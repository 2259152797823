html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {
    border-color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
}

hr.light {
    border-color: white;
}

a {
    @include transition-all;
    color: $theme-primary;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include sans-serif-font;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.bg-primary {
    background-color: $theme-primary;
}

.bg-dark {
    background-color: $theme-dark;
    color: white;
}

.text-faded {
    color: rgba(white, .7);
}

section {
    padding: 100px 0;
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

// Navigation

.navbar-default {
    background-color: white;
    border-color: rgba($theme-dark, .5);
    @include sans-serif-font;
    @include transition-all;
    .navbar-header .navbar-brand {
        color: $theme-primary;
        @include sans-serif-font;
        font-weight: 700;
        text-transform: uppercase;
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .nav {
        > li {
            > a,
            > a:focus {
                text-transform: uppercase;
                font-weight: 700;
                font-size: 13px;
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: $theme-primary !important;
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        background-color: transparent;
        border-color: rgba(white, .3);
        .navbar-header .navbar-brand {
            color: rgba(white, .7);
            &:hover,
            &:focus {
                color: white;
            }
        }
        .nav > li > a,
        .nav > li > a:focus {
            color: rgba(white, .7);
            &:hover {
                color: white;
            }
        }
        &.affix {
            background-color: white;
            border-color: rgba($theme-dark, .5);
            .navbar-header .navbar-brand {
                color: $theme-primary;
                font-size: 14px;
                &:hover,
                &:focus {
                    color: darken($theme-primary, 10%);
                }
            }
            .nav > li > a,
            .nav > li > a:focus {
                color: $theme-dark;
                &:hover {
                    color: $theme-primary;
                }
            }
        }
    }
}

// Homepage Header

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header.jpg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 300;
                color: rgba(white, .7);
                font-size: 16px;
                margin-bottom: 50px;
            }
        }
    }
    @media (min-width: 768px) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 18px;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

// Sections

.section-heading {
    margin-top: 0;
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
}

.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    .portfolio-box-caption {
        color: white;
        opacity: 0;
        display: block;
        background: rgba( $theme-primary, .9 );
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
        height: 100%;
        @include transition-all;
        .portfolio-box-caption-content {
            width: 100%;
            text-align: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .project-category,
            .project-name {
                @include sans-serif-font;
                padding: 0 15px;
            }
            .project-category {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 14px;
            }
            .project-name {
                font-size: 18px;
            }
        }
    }
    &:hover {
        .portfolio-box-caption {
            opacity: 1;
        }
    }
    @media (min-width: 768px) {
        .portfolio-box-caption {
            .portfolio-box-caption-content {
                .project-category {
                    font-size: 16px;
                }
                .project-name {
                    font-size: 22px;
                }
            }
        }
    }
}

.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

// Bootstrap Overrides
.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($theme-dark, white, white);
}

.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-xl {
    padding: 15px 30px;
}

// Contact
#contact .fa {
    color: $theme-dark;
    font-size: 4em;
}

// Homepage Footer

#footer {
    position: relative;
    width: 100%;
    //min-height: auto;
    //@include background-cover;
    background-position: center;
    background-image: url('../img/footer.jpg');
    text-align: center;
    color: white;
    .footer-content {
        position: relative;
        text-align: center;
        //padding: 100px 15px 100px;
        width: 100%;
        .footer-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 1000;
                color: white; //<rgba(white, .7); //$theme-primary;
                font-size: 16px;
                //margin-bottom: 50px;
            }
        }
    }
}

// Timeline

$body-bg: #ffffff;
$text-color: #373737;
$gray-base: #494949;
$gray-darker: #222;
$gray-dark: #333;
$gray: #555;
$gray-light: #777;
$gray-lighter: #eee;
$brand-primary: #1f9eba;
$brand-success: #59ba1f;
$brand-info: #5bc0de;
$brand-warning: #d1bd10;
$brand-danger: #ba1f1f;
//$brand-colgate: #862633;
//$brand-ubc: #002145;
//$brand-uoft: #00204E;

/* Timeline */
.timeline {
    list-style: none;
    padding: 20px;
    position: relative;
	&:before {
        top: 40px;
        bottom: 150px; //0: will continue timeline
        position: absolute;
        content: " ";
        width: 3px;
        background-color: #eeeeee;
        left: 50%;
        margin-left: -1.5px;
    }
	.timeline-item {
        margin-bottom: 20px;
        position: relative;
		&:before,
		&:after {
            content: "";
            display: table;
        }
		&:after {
            clear: both;
        }
		.timeline-badge {
            color: #fff;
            width: 54px;
            height: 54px;
            line-height: 52px;
            font-size: 22px;
            text-align: center;
            position: absolute;
            top: 18px;
            left: 50%;
            margin-left: -25px;
            background-color: $gray-dark;
			border: 3px solid $body-bg;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
			i, .fa, .glyphicon {
				top: 2px;
				left: 0px;
			}
			&.primary {
				background-color: $brand-primary;
			}
			&.info {
				background-color: $brand-info;
			}
			&.success {
				background-color: $brand-success;
			}
			&.warning {
				background-color: $brand-warning;
			}
			&.danger {
				background-color: $brand-danger;
			}
      //&.colgate {
			// 	background-color: $brand-colgate;
			// }
      // &.ubc {
			// 	background-color: $brand-ubc;
			// }
      // &.uoft {
			// 	background-color: $brand-uoft;
			// }
        }
		.timeline-panel {
            position: relative;
            width: 46%;
            float: left;
			right: 16px;
            border: 1px solid $gray-light;
			background: $body-bg;
            border-radius: 2px;
            padding: 20px;
            -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
            box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
			&:before {
                position: absolute;
                top: 26px;
                right: -16px;
                display: inline-block;
                border-top: 16px solid transparent;
                border-left: 16px solid $gray-light;
                border-right: 0 solid $gray-light;
                border-bottom: 16px solid transparent;
                content: " ";
            }
			.timeline-title {
    			margin-top: 0;
    			color: inherit;
			}
			.timeline-body > p,
			.timeline-body > ul {
    			margin-bottom: 0;
			}
			.timeline-body > p + p {
        		margin-top: 5px;
    		}
        }
    }
	.timeline-item:last-child {
		&:nth-child(even) {
			float: right;
		}
	}
	.timeline-item:nth-child(even) {
		.timeline-panel {
			float: right;
			left: 16px;
			&:before {
				border-left-width: 0;
				border-right-width: 14px;
				left: -14px;
				right: auto;
			}
		}
	}
}
.timeline-horizontal:extend(.timeline) {
    list-style: none;
    position: relative;
	padding: 20px 0px 20px 0px;
	display: inline-block;
	&:before {
        height: 3px;
		top: auto;
        bottom: 26px;
		left: 56px;
		right: 0;
		width: 100%;
		margin-bottom: 20px;
    }
	.timeline-item {
		display: table-cell;
		height: 280px;
		width: 20%;
		min-width: 320px;
		float: none !important;
		padding-left: 0px;
		padding-right: 20px;
		margin: 0 auto;
		vertical-align: bottom;
		.timeline-panel {
			top: auto;
			bottom: 64px;
			display: inline-block;
			float: none !important;
			left: 0 !important;
			right: 0 !important;
			width: 100%;
			margin-bottom: 20px;
			&:before {
            	top: auto;
            	bottom: -16px;
				left: 28px !important;
				right: auto;
                border-right: 16px solid transparent !important;
                border-top: 16px solid $gray-light !important;
                border-bottom: 0 solid $gray-light !important;
                border-left: 16px solid transparent !important;
            }
		}
		&:before,
		&:after {
            display: none;
        }
		.timeline-badge {
            top: auto;
            bottom: 0px;
            left: 43px;
        }

	}
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}
